<template>
  <div class="resident-info">
    <div class="top">
        <div class="card">
            <div class="card-top" v-if="codeType==2">
                <div class="fullName" :class="{'isOld36': $isOld}">{{houseInfo.communityName}}-{{houseInfo.subareaName}}-{{houseInfo.fullName}}</div>
                <div class="top-down">
                    <div class="text" :class="{'isOld30': $isOld}">地址编码：<span>{{houseInfo.code}}</span></div>
                    <div class="text" :class="{'isOld30': $isOld}">标准地址：<span>{{houseInfo.codeName}}</span></div>
                </div>
            </div>
            <div class="card-top-mini" v-else>
                <div class="fullName" :class="{'isOld36': $isOld}">{{houseInfo.communityName}}-{{houseInfo.subareaName}}-{{houseInfo.fullName}}</div>
            </div>
            <div class="card-down">
                <div class="signs">
                    <div class="signs-item" :class="{'isOld28': $isOld}">{{houseInfo.useTypeStr}}</div>
                </div>
                <div class="text" :class="{'isOld28': $isOld}">所属网格：<span>{{houseInfo.gridName || '无'}}</span></div>
                <div class="text" :class="{'isOld28': $isOld}">面积：<span>{{houseInfo.areas}}</span></div>
            </div>
            <div class="filter"></div>
        </div>
    </div>
    <div class="checked-item">
        <div class="item" :class="{'checked': item.isChecked,'isOld36': $isOld}"
            v-for="item in checkedItemList" :key="item.id"
            @click="checkedItem(item.id)"
        >
            {{item.name}}<br /><span>{{item.num}}</span>人
        </div>
    </div>
    <div class="resident-list">
        <div class="list-item" v-for="item in dataList" :key="item.id" @click="goUser(item.id,false)">
            <div class="item-top">
                <div class="left" :class="{'isOld32': $isOld}">{{item.relationshipStr}}</div>
                <div class="right">
                    <div class="register" :class="{'isOld28': $isOld}">{{item.registryTypeName}}</div>
                    <img class="mobile" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(item.id)" />
                </div>
            </div>
            <div class="item-mid">
                <van-image :src="item.headImg || require('@/assets/img/headImg.png')" class="header-img">
                    <template slot="loading">
                        <img :src="require('@/assets/img/headImg.png')" alt="" class="header-img">
                    </template>
                    <template slot="error">
                        <img :src="require('@/assets/img/headImg.png')" alt="" class="header-img">
                    </template>
                </van-image>
                <div class="mid-text">
                    <div class="text-top">
                        <div class="nam" :class="{'isOld32': $isOld}">{{item.name}}</div>
                        <div class="signs" v-if="item.labels">
                            <div class="sings-item" v-for="(it,inx) in item.labelsArray" :key="inx" :class="{'isOld28': $isOld}">{{it}}</div>
                            <!-- <div class="sings-item" :class="{'isOld28': $isOld}">党</div> -->
                        </div>
                    </div>
                    <div class="text-mid" :class="{'isOld28': $isOld}">
                        <span>{{item.sexName}}</span>
                        <span>{{item.age}}</span>
                        <span class="mobile">{{item.mobile}}</span>
                    </div>
                    <div class="text-mid" :class="{'isOld28': $isOld}">
                        {{item.idNumber}}
                    </div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="item-down" :class="{'isOld32': $isOld}">
                <div class="buttons move" @click.stop="moveAway(item.moveId)">搬离</div>
                <div class="buttons editor" @click.stop="goUser(item.id,true)">修改</div>
            </div>
        </div>
        <van-image v-if="dataList.length < 1" :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    </div>
    <div class="add-box" @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      ref="fu" @click="goAdd">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {getRealMobile} from "@/utils/common"
import {mapMutations, mapState} from 'vuex'
import {getImageStream} from '@/utils/index'
export default {
    props: ['houseInfo','orgRegistry'],
    data() {
        return {
            dataList: [],
            checkedItemList: [
                {id: 0,name: '共有',num: 0,isChecked: true},
                {id: 2,name: '实有',num: 0,isChecked: false},
                {id: 1,name: '常住',num: 0,isChecked: false},
                {id: 3,name: '户籍',num: 0,isChecked: false},
                {id: 4,name: '流动',num: 0,isChecked: false},
            ],
            flags: false, //控制使用
            position: {
                x: 0,
                y: 0,
            },
            nx: "",
            ny: "",
            dx: "",
            dy: "",
            xPum: "",
            yPum: "",
            relationshipList: [],
          codeType: 1
        }
    },
    computed: {...mapState(['houseId'])},
    mounted() {
        this.checkedItemList[0].num = this.orgRegistry[4].count
        this.checkedItemList[1].num = this.orgRegistry[1].count
        this.checkedItemList[2].num = this.orgRegistry[0].count
        this.checkedItemList[3].num = this.orgRegistry[2].count
        this.checkedItemList[4].num = this.orgRegistry[3].count
        this.codeType = Vue.prototype.$globalData.userInfo.codeType
        this.getRelationship()
        this.$nextTick(() => {
            this.getResidentList(0)
        })
        // this.getHouseInfo()
    },
    methods: {
        getUserMobile(id) {
            getRealMobile(id,function(data) {
                window.location.href = `tel://${data.mobile}`
            })
        },
        getHouseInfo() {
            this.$http({
                url: this.$http.adornUrl(`/wxapp/building/house/info/${this.houseId}`),
                method: 'post',
                // params: this.$http.adornParams({
                //     id: this.houseId
                // })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    this.houseInfo = data.buildingHouse
                    switch (data.buildingHouse.useType) {
                    case 0:
                        this.houseInfo.useTypeStr = ["未"];
                        break;
                    case 1:
                        this.houseInfo.useTypeStr = ["自"];
                        break;
                    case 2:
                        this.houseInfo.useTypeStr = ["租"];
                        break;
                    case 3:
                        this.houseInfo.useTypeStr = ["半"];
                        break;
                    case 4:
                        this.houseInfo.useTypeStr = ["商"];
                        break;
                    case 5:
                        this.houseInfo.useTypeStr = ["拆"];
                        break;
                    case 6:
                        this.houseInfo.useTypeStr = ["空"];
                        break;
                    }
                    this.houseInfo.code = data.buildingHouse.code
                    this.houseInfo.codeName = data.buildingHouse.codeName
                    this.houseInfo.gridName = data.buildingHouse.gridName
                    this.houseInfo.areas = data.buildingHouse.areas
                    this.checkedItemList[0].num = data.orgRegistry[4].count
                    this.checkedItemList[1].num = data.orgRegistry[1].count
                    this.checkedItemList[2].num = data.orgRegistry[0].count
                    this.checkedItemList[3].num = data.orgRegistry[2].count
                    this.checkedItemList[4].num = data.orgRegistry[3].count
                    this.$store.commit("setSubarea", data.buildingHouse.subarea);
                    this.$store.commit("setBuildingId", data.buildingHouse.buildingId);
                    this.$store.commit("setUnitId", data.buildingHouse.unitId);
                    this.$store.commit("setSubareaName", data.buildingHouse.subareaName);
                    this.$store.commit("setFullName", data.buildingHouse.fullName);
                }else {
                    this.$toast.fail(data.msg)
                }
            })
        },
        getRelationship() {
            this.$http({
                url: this.$http.adornUrl('/wxapp/user/resident/newList'),
                method: 'GET',
                params: this.$http.adornParams({
                    page: 1,
                    limit: -1,
                    houseId: this.houseId,
                    showLabel: 1,
                    resDepth: 5
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    this.relationshipList = data.result.list[0]
                }
            })
        },
        getResidentList(orgRegistry) {
            this.$http({
                url: this.$http.adornUrl('/wxapp/user/res/list'),
                method: 'GET',
                params: this.$http.adornParams({
                    orgId: this.$orgId,
                    houseId: this.houseId,
                    page: 1,
                    limit: -1,
                    orgRegistry,
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    data.page.list.forEach(item => {
                        // 处理头像
                        if(item.headImg) {
                            item.headImg = getImageStream(item.headImg)
                        }
                        // 处理重要标签
                        if (item.labelShorts) {
                            item['labelsArray'] = item.labelShorts.split(',')
                        }
                        /**
                         * wxapp/user/res/list无法返回与房主关系，
                         * 另外请求wxapp/user/resident/list，将获取到的数据（relationshipList）进行匹配赋值
                         */
                        this.relationshipList.forEach(it => {
                            if(item.id === it.userId) {
                                item.relationship = it.relationship
                                item.relationshipStr = it.relationshipStr
                                item.moveId = it.id
                            }
                        })
                    })
                    this.dataList = data.page.list
                    // wxapp/user/res/list无法返回与房主关系，另外请求wxapp/user/resident/list，将获取到的数据进行匹配赋值
                    // this.$http({
                    //     url: this.$http.adornUrl('/wxapp/user/resident/newList'),
                    //     method: 'GET',
                    //     params: this.$http.adornParams({
                    //         page: 1,
                    //         limit: -1,
                    //         houseId: this.houseId,
                    //         showLabel: 1,
                    //         resDepth: 5
                    //     })
                    // }).then((res) => {
                    //     if(res.data && res.data.code === 0) {
                    //       res.data.result.list[0].forEach(item => {
                    //             if(item.headImg) {
                    //                 item.headImg = getImageStream(item.headImg)
                    //             }
                    //             if (item.labelShorts) {
                    //                 item['labelsArray'] = item.labelShorts.split(',')
                    //             }
                    //         })
                    //         this.dataList = res.data.result.list[0]
                    //     }else {
                    //         this.$toast.fail(data.msg)
                    //     }
                    // })
                }else {
                    this.$toast.fail(data.msg)
                }
            })
        },
        checkedItem(id) {
            this.checkedItemList.forEach(item => {
                item.isChecked = false
                if(item.id == id) {
                    item.isChecked = true
                    this.getResidentList(id)
                }
            })
        },
        moveAway(id) {
            this.$dialog.confirm({
                title: '搬离',
                message: '确认该人员搬离吗',
            }).then(() => {
              this.$http({
                url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
                method: 'post',
                params: this.$http.adornParams({
                  id: id
                })
              }).then(({data}) => {
                    if (data.code == 0) {
                        this.$toast.success({message: '成功'})
                        this.getHouseInfo()
                        this.getResidentList()
                    } else {
                        this.$toast.fail({message: data.msg})
                    }
                }, err => {
                    this.$toast.fail({message: err})
                })
            })
        },
        goUser(userId,editor) {
            // this.$store.commit("setActive", this.active);
            this.$router.push({path: '/userRes-info', query: {userId: userId,editor}})
        },
        goAdd() {
            this.houseInfo.houseFlag = true
            this.$router.push({path: '/userRes-info', query: this.houseInfo})
        },
        down(event) {
            this.flags = true;
            var touch;
            if (event.touches) {
                touch = event.touches[0];
            } else {
                touch = event;
            }
            this.position.x = touch.clientX;
            this.position.y = touch.clientY;
            this.dx = this.$refs.fu.offsetLeft;
            this.dy = this.$refs.fu.offsetTop;
        },
        move(event) {
            if (this.flags) {
                var touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.nx = touch.clientX - this.position.x;
                this.ny = touch.clientY - this.position.y;
                this.xPum = this.dx + this.nx;
                this.yPum = this.dy + this.ny;
                let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
                let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
                this.xPum < 0 && (this.xPum = 0);
                this.yPum < 0 && (this.yPum = 0);
                this.xPum > width && (this.xPum = width);
                this.yPum > height && (this.yPum = height);
                this.$refs.fu.style.left = this.xPum + "px";
                this.$refs.fu.style.top = this.yPum + "px";
                //阻止页面的滑动默认事件
                document.addEventListener("touchmove",function (event) {
                    event.preventDefault();
                },false);
            }
        },
        //鼠标释放时候的函数
        end() {
            this.flags = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.resident-info {
    .top {
        // height: 466px;
        background-color: #FFFFFF;
        padding: 24px 30px 44px;
        z-index: 99 !important;
        .card {
            position: relative;
            .card-top {
                width: 690px;
                height: 320px;
                background: url('~@/assets/img/house-info-bg.png') no-repeat;
                background-size: 100% 100%;
                box-shadow: 0 0 24px 0 rgba(113,134,177,0.50);
                border-radius: 12px 12px 0 0;
                padding: 24px;
                .fullName {
                    width: 100%;
                    text-align: center;
                    font-size: 28px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 600;
                    color: #FFFFFF;
                }
                .top-down {
                    height: 256px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
                .title {
                    font-size: 26px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 500;
                    color: #FFFFFF;
                    // margin: 12px 0;
                }
                .text {
                    // margin: 12px 0;
                    font-size: 24px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 400;
                    color: #FFFFFF;
                    span {
                        font-weight: 600;
                    }
                }
            }
            .card-top-mini {
                width: 690px;
                height: 90px;
                background: linear-gradient(225deg, #7BB7FC 0%, #4581F8 100%);
                box-shadow: 0 0 24px 0 rgba(113,134,177,0.5);
                border-radius: 12px 12px 0 0;
                font-size: 28px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 90px;
                text-align: center;
            }
            .card-down {
                width: 690px;
                height: 78px;
                background: #FFFFFF;
                border-radius: 0 0 12px 12px;
                padding-left: 24px;
                display: flex;
                align-items: center;
                z-index: 9999;
                position: relative;
                .signs {
                    display: flex;
                    .signs-item {
                        background: rgba(78,124,245,0.12);
                        border-radius: 4px;
                        padding: 5px 10px;
                        margin-right: 16px;
                        font-size: 24px;
                        font-family: 'PingFang Bold';
                        font-weight: 500;
                        color: #4581F8;
                    }
                }
                .text {
                    font-size: 24px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 400;
                    color: #666666;
                    margin: 0 24px 0 12px;
                    span {
                        font-weight: bold;
                    }
                }
            }
            .filter {
                position: absolute;
                left: 46px;
                bottom: -20px;
                width: 600px;
                height: 24px;
                background: radial-gradient(circle at center, #6996FF 100%, #83CAFF 0%);
                opacity: 0.25;
                filter: blur(24px);
            }
        }
    }
    .checked-item {
        height: 132px;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        margin-bottom: 24px;
        .item {
            height: 100%;
            font-size: 32px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #666666;
            padding-top: 22px;
            span {
                font-size: 36px;
                font-family: 'DINPro-Bold';
                font-weight: bold;
                color: #333333;
            }
        }
        .checked {
            color: #4581F8;
            border-bottom: 4px solid #4581F8;
            span {
                color: #4581F8;
            }
        }
    }
    .resident-list {
        .list-item {
            background-color: #FFFFFF;
            padding: 0 30px;
            margin-bottom: 16px;
            .item-top {
                height: 102px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                .left {
                    font-size: 28px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 400;
                    color: #666666;
                }
                .right {
                    display: flex;
                    .register {
                        background: rgba(78,124,245,0.12);
                        border-radius: 8px;
                        padding: 9px 20px;
                        font-size: 24px;
                        font-weight: 600;
                        color: #4E7CF5;
                    }
                    .mobile {
                        width: 40px;
                        height: 40px;
                        margin-left: 40px;
                    }
                }
            }
            .item-mid {
                height: 188px;
                padding: 24px 0;
                display: flex;
                align-items: center;
                .header-img {
                    width: 120px;
                    height: 120px;
                    margin-right: 16px;
                }
                .mid-text {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    .text-top {
                        display: flex;
                        align-items: center;
                      font-size: 28px;
                        .name {
                            font-size: 28px;
                            font-weight: 600;
                            color: #333333;
                        }
                        .signs {
                            display: flex;
                            align-items: center;
                            .sings-item {
                                padding: 5px 10px;
                                background: rgba(69,129,248,0.12);
                                border-radius: 4px;
                                font-size: 24px;
                                font-weight: 600;
                                color: #4581F8;
                                margin-left: 16px;
                            }
                        }
                    }
                    .text-mid {
                        font-size: 28px;
                        font-weight: 400;
                        color: #666666;
                        span {
                            margin-right: 10px;
                        }
                        .mobile {
                            color: #4581F8;
                        }
                    }
                }
            }
            .item-down {
                height: 106px;
                font-size: 28px;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid rgba(0,0,0,0.1);
                .buttons {
                    width: 324px;
                    height: 60px;
                    border-radius: 8px;
                    line-height: 60px;
                    text-align: center;
                }
                .move {
                    background: #FFFFFF;
                    border: 2px solid #4581F8;
                    color: #4581F8;
                }
                .editor {
                    background: #4581F8;
                    border-radius: 8px;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>
