<template>
  <div class="page">
    <div class="top">
      <resident :houseInfo="houseInfo" :orgRegistry="orgRegistry" v-if="activeName == 1"/>
      <house-info :houseInfo="houseInfo" :labels="labels" :urls="urls" :check-type="checkType" v-if="activeName == 2" />
      <manager :gridUserList="gridUserList" :assistList="assistList" :houseInfo="houseInfo" v-if="activeName == 3" />
      <visit v-if="activeName == 4" :isVisitOpen="isVisitOpen" :visitAppInfo="visitAppInfo"/>
      <repairs-And-Problems
        v-if="activeName == 5"
        :houseId="houseId"
        :isRepairsOpen="isRepairsOpen"
        :repairAppInfo="repairAppInfo"
        :isProblemOpen="isProblemOpen"
        :problemAppInfo="problemAppInfo"
      />
      <car :houseId="houseId" v-if="activeName == 6" />
    </div>
    <div class="down">
      <div class="title" :class="{'isOld36': $isOld}">选择信息</div>
      <div class="check-item">
        <div class="item"
          v-for="item in checkItemList" :key="item.id"
          :class="{'checked': item.isChecked,'isOld32': $isOld}"
          @click="checkItem(item.id)"
             v-show="!item.isShow"
        >

          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resident from './resident'
import houseInfo from './info'
import manager from './manager'
import visit from './visit'
import repairsAndProblems from './repairsAndProblems'
import car from './car'
import {getImageStream} from '@/utils'
import {getAppToken} from '@/utils/common'
export default {
  components: {
    resident,
    houseInfo,
    manager,
    visit,
    repairsAndProblems,
    car
  },
  data() {
    return {
      houseId: '',
      activeName: null,
      houseInfo: {},
      orgRegistry: [],
      labels: [],
      urls: [],
      gridUserList: [],
      assistList: [],
      checkType: null,
      checkItemList: [
        {id: 1,name: '房屋信息',isChecked: true,},
        {id: 2,name: '基本信息',isChecked: false,},
        {id: 3,name: '管理信息',isChecked: false,},
        {id: 4,name: '走访信息',isChecked: false, isShow: true, appCode: 'sjgl_sgzf_zfjl'},
        {id: 5,name: '报修与问题',isChecked: false, isShow: true, appCode: 'sjgl_bxjl,sjgl_wtjl'},
        {id: 6,name: '车辆情况',isChecked: false,},
      ],
      isRepairsOpen: false,
      repairAppInfo: {},
      isProblemOpen: false,
      problemAppInfo: {},
      isVisitOpen: false,
      visitAppInfo: {}
    }
  },
  mounted() {
    this.houseId = this.$route.query.id
    this.checkType = parseInt(this.$route.query.checkType)
    this.getInfo()
    this.getAppInfo()
  },
  methods:{
    getInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/info/${this.houseId}`),
        method: 'GET'
      }).then(({data}) => {
        if(data && data.code === 0) {
          if(this.$route.query.activeName) {
            this.activeName = parseInt(this.$route.query.activeName)
            this.checkItemList.forEach(item => {
              item.isChecked = false
              if(item.id == this.activeName) {
                item.isChecked = true
              }
            })
          }else {
            this.activeName = 1
          }
          this.gridUserList = data.gridUserList
          this.assistList = data.assistList
          this.orgRegistry = data.orgRegistry
          this.houseInfo = data.buildingHouse
          // 房屋标签
          var houseLabels = data.labels;
          var labels = []
          if (houseLabels != null) {
            labels = this.formatLabel(houseLabels, true);
          }
          this.labels = labels
          //获取图片流
          let urls = [];
          if (data.buildingHouse.imgUrl) {
            let imgUrls = data.buildingHouse.imgUrl.split(",");

            for (let i in imgUrls) {
              let u;
              let file = imgUrls[i];
              u = getImageStream(imgUrls[i]);
              let url = {
                uid: urls.length,
                status: "done", //uploading上传中，done上传完成，error上传失败
                url: u,
                fileType: "image/png",
                realUrl: file.replace(/\\/g, "/"),
              };
              urls.push(url);
            }
          }
          this.urls = urls;
        }
      })
    },
    checkItem(id) {
      // 添加选中状态
      this.checkItemList.forEach(item => {
        // 先将其他项选中状态取消
        item.isChecked = false
        if(item.id == id) {
          // 给点击项加上选中状态
          item.isChecked = true
          this.activeName = id
        }
      })
    },
     /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_bxjl,sjgl_wtjl,sjgl_sgzf_zfjl',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {
            this.checkItemList.map(items => {
              if (items.appCode && items.appCode.indexOf(item.appCode) > -1){
                items.isShow = false
              }
            })
            if(item.appCode === 'sjgl_bxjl') {
              this.isRepairsOpen = true
              this.repairAppInfo = item
            }
            if(item.appCode === 'sjgl_wtjl') {
              this.isProblemOpen = true
              this.problemAppInfo = item
            }
            if(item.appCode === 'sjgl_sgzf_zfjl') {
              this.isVisitOpen = true
              this.visitAppInfo = item
            }
          })
          // if(data.list && data.list.length) {
          //   let appId = data.list[0].appId
          //   getAppToken(appId)
          //   // this.$http({
          //   //   url: this.$http.adornUrl('/wxapp/application/appToken'),
          //   //   method: 'get',
          //   //   params: this.$http.adornParams({
          //   //     appId,
          //   //   })
          //   // }).then(({data}) => {
          //   //   if(data && data.code === 0) {
          //   //     this.$httpApp({
          //   //       url: this.$httpApp.adornUrl('/auth/getLoginUser'),
          //   //       method: 'GET',
          //   //       params: this.$httpApp.adornParams({
          //   //         appId,
          //   //         appToken: data.appToken
          //   //       })
          //   //     }).then(({data}) => {
          //   //       if(data && data.code === 0) {
          //   //         sessionStorage.setItem('appToken', data.appToken)
          //   //       }
          //   //     })
          //   //   }
          //   // })
          // }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 16px;
  .top {
    padding-bottom: 344px;
  }
  .down {
    background: #FFFFFF;
    margin-top: 24px;
    position: fixed;
    bottom: 0;
    .title {
      height: 88px;
      padding-left: 30px;
      font-size: 32px;
      font-family: 'PingFang Bold';
      font-weight: 600;
      color: #333333;
      line-height: 88px;
    }
    .check-item {
      height: 216px;
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-between;
      padding: 0 30px;
      .item {
        width: 192px;
        height: 72px;
        border-radius: 8px;
        border: 2px solid #EEEEEE;
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 72px;
      }
      .checked {
        background: #4581F8;
        color: #FFFFFF;
      }
    }
  }
}
</style>
