<template>
  <div class="page-info">
    <div class="title">
      <div class="left">走访信息</div>
      <div class="right" @click="goVisit">
        更多
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="visit-list" v-if="dataList.length">
      <div class="list-item" v-for="item in dataList" :key="item.id" @click="goVisitInfo(item.id, item.visitBody, item.fullLocation)">
        <div class="item-top">
          <div class="left">{{item.createUserName}} {{item.createTime}}</div>
          <div class="right">{{item.visitTypeStr}}</div>
        </div>
        <div class="item-down">
          <van-image :src="item.url || require('@/assets/img/default.png')" class="down-left">
            <template slot="loading">
              <img :src="require('@/assets/img/default.png')" alt="" class="down-left">
            </template>
            <template slot="error">
              <img :src="require('@/assets/img/default.png')" alt="" class="down-left">
            </template>
          </van-image>
          <div class="down-mid">
            <div class="down-mid-top">{{item.fullLocation}}</div>
            <div class="down-mid-down">{{item.visitDes}}</div>
          </div>
          <van-icon size="20" name="arrow" />
        </div>
      </div>
    </div>
    <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import {getImageStream} from '@/utils'
export default {
  props: ['isVisitOpen','visitAppInfo'],
  computed: {...mapState(['houseId','subarea','buildingId','unitId','subareaName','fullName'])},
  data() {
    return {
      dataList: [],
    }
  },
  created() {
    if(this.isVisitOpen) {
      this.getVisitList()
    }
  },
  methods: {
    getVisitList() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/visit/list'),
        method: 'GET',
        params: this.$httpApp.adornParams({
          houseId: this.houseId,
          page: 1,
          limit: 4,
          loginUserType: 1
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.page.list.forEach(item => {
            if (item.filesPath && item.filesPath.length != 0) {
              let file = item.filesPath[0]
              let url = file.filePath
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.dataList = data.page.list
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    goVisitInfo(id, visitType, location) {
      // visitType 1房屋，2场所
      let type = ''
      if (visitType == 2) {
        type = '3'
      }else if(visitType == 4) {
        type = '4'
      }else if(visitType == 5) {
        type =' 5'
      } else {
        type = location != '无房户' ? '1' : '2'
      }
      this.$router.push({
        path: '/appSSO',
        query: {
          dataId: id,
          visitType: type,
          appId: this.visitAppInfo.appId,
          appCode: this.visitAppInfo.appCode,
          appName: this.visitAppInfo.appName,
          url: this.visitAppInfo.pageUrlH5,
          // url: 'http://192.168.0.12:8081?url=visit',
          isToInfo: true,
        }
      })
    },
    goVisit() {
      this.$router.push({
        path: '/appSSO',
        query: {
          houseId: this.houseId + '',
          appId: this.visitAppInfo.appId,
          appCode: this.visitAppInfo.appCode,
          appName: this.visitAppInfo.appName,
          url: this.visitAppInfo.pageUrlH5,
          // url: 'http://192.168.0.12:8081?url=visit',
          subarea: this.subarea + '',
          buildingId: this.buildingId + '',
          unitId: this.unitId + '',
          buildingStr: this.subareaName + '-' + this.fullName
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.page-info {
  padding-top: 24px;
  .title {
    height: 96px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .left {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
    }
    .right {
      font-size: 28px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
    }
  }
  .visit-list {
    .list-item {
      padding: 0 30px;
      background: #FFFFFF;
      margin-bottom: 16px;
      .item-top {
        height: 102px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-size: 28px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: #666666;
        }
        .right {
          padding: 9px 20px;
          background: rgba(78,124,245,0.12);
          border-radius: 8px;
          font-size: 24px;
          font-weight: 600;
          color: #4E7CF5;
        }
      }
      .item-down {
        height: 214px;
        padding: 22px 0 32px;
        display: flex;
        align-items: center;
        .down-left {
          width: 160px;
          height: 160px;
          margin-right: 16px;
        }
        .down-mid {
          // flex: 1;
          width: 480px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;
          padding: 8px 0;
          white-space:normal;
          word-break:break-all;
          overflow:hidden;
          .down-mid-top {
            font-size: 28px;
            font-weight: 600;
            color: #333333;
          }
          .down-mid-down {
            font-size: 28px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #666666;
            word-wrap:break-word;
          }
        }
      }
    }
  }
}
</style>