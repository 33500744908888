<template>
  <div class="page-info">
    <div class="list-item" v-for="item in carList" :key="item.id" @click="goInfo(item.id,item.subarea)">
      <div class="item-top">
        <div class="top-left">{{item.subareaStr}}</div>
        <div class="top-right" @click.stop="illPark(item.id, item.userId, item.subarea, item.subareaStr)">违停</div>
      </div>
      <div class="item-down">
        <van-image :src="require('@/assets/img/default.png')" class="default-img">
          <template slot="loading">
            <img :src="require('@/assets/img/default.png')" alt="" class="default-img">
          </template>
          <template slot="error">
            <img :src="require('@/assets/img/default.png')" alt="" class="default-img">
          </template>
        </van-image>
        <div class="text-mid">
          <div class="mid-name">
            {{item.userName}}
            <div class="car-type">{{item.typeStr}}</div>
          </div>
          <div class="mid-mobile">
            {{item.mobile}}
            <img class="mobile" :src="require('@/assets/img/mobile.png')" alt="" @click.stop="getUserMobile(item.userId)">
          </div>
          <div>{{item.brand}}/{{item.carNumber}}</div>
        </div>
        <van-icon name="arrow" size="20"/>
      </div>
    </div>
    <van-image v-if="carList.length == 0" :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
  </div>
</template>

<script>
import {getRealMobile} from "@/utils/common"
export default {
  props: ['houseId'],
  data() {
    return {
      carList: [],
    }
  },
  mounted() {
    this.getCarList()
  },
  methods: {
    getCarList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/infoCarInformation'),
        method: 'post',
        params: this.$http.adornParams({
          houseId: this.houseId,
          orgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          this.carList = data.data || []
        }else {
          this.$toast.fail(data.msg)
        }
      }, err => {
      })
    },
    getUserMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel://${data.mobile}`
      })
    },
    illPark (id, userId, subarea, subareaStr) {
      this.$router.push({path: '/car-illPark', query: {carId: id, userId, subarea, subareaStr}})
    },
    goInfo (id, subarea) {
      this.$router.push({path: '/car-add', query: {id: id, subarea: subarea}})
    },
  }
}
</script>

<style lang="scss" scoped>
.page-info {
  .list-item {
    padding: 0 30px;
    background: #FFFFFF;
    margin-bottom: 16px;
    .item-top {
      padding: 24px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .top-left {
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
      }
      .top-right {
        width: 112px;
        height: 60px;
        background: #4581F8;
        border-radius: 8px;
        font-size: 28px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
      }
    }
    .item-down {
      display: flex;
      align-items: center;
      padding: 22px 0;
      .default-img {
        width: 160px;
        height: 160px;
        margin-right: 16px;
      }
      .text-mid {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160px;
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        color: #666666;
        font-weight: 400;
        .mid-name {
          font-size: 28px;
          font-weight: 600;
          color: #333333;
          display: flex;
          align-items: center;
          .car-type {
            padding: 9px 20px;
            background: rgba(78,124,245,0.12);
            border-radius: 8px;
            font-size: 24px;
            font-weight: 500;
            color: #4E7CF5;
            margin-left: 16px;
          }
        }
        .mid-mobile {
          color: #4581F8;
          .mobile {
            width: 40px;
            height: 40px;
            margin-left: 40px;
          }
        }
      }
    }
  }
}
</style>
