<template>
  <div class="page-info">
    <van-tabs>
        <van-tab title="报修情况" v-if="isRepairsOpen">
            <div class="totalcount">共有 <span>{{repairTotalCount || 0}}</span> 条数据</div>
            <div class="title">
                <div class="left">报修</div>
                <div class="right" v-if="isRepairsOpen" @click="goRepair">更多<van-icon name="arrow" /></div>
            </div>
            <div class="list-item" v-for="item in repairList" :key="item.id" @click="goRepairInfo(item.id,item.solveStatus)">
                <div class="item-top">
                    <div class="top-left">{{item.userName}} {{item.mobile}} <img v-if="item.mobile" class="mobile" :src="require('@/assets/img/mobile.png')" alt=""></div>
                    <div class="top-right unfinished"
                        :class="{
                            'unfinished': item.solveStatus == 0 || item.solveStatus == 10 || item.solveStatus == 20,
                            'finished': item.solveStatus == 30 || item.solveStatus == 40 || item.solveStatus == 50,
                        }"
                    >{{item.solveStatusStr}}</div>
                </div>
                <div class="item-mid">
                    <van-image :src="item.url || require('@/assets/img/default.png')" class="content-img">
                        <template slot="loading">
                            <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                        </template>
                        <template slot="error">
                            <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                        </template>
                    </van-image>
                    <div class="mid-text">
                        <div class="text-top">{{item.fullLocation}}<span>（{{item.repairTypeStr}}）</span></div>
                        <div class="text-content">{{item.repairDes}}</div>
                    </div>
                    <van-icon size="20" name="arrow" />
                </div>
                <div class="item-down">
                    <div class="down-left">{{item.createTime}}</div>
                    <div class="down-right">处理</div>
                </div>
            </div>
            <van-image v-if="repairList.length < 1" :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
        </van-tab>
        <van-tab title="问题情况" v-if="isProblemOpen">
            <div class="totalcount">共有 <span>{{problemTotalCount || 0}}</span> 条数据</div>
            <div class="title">
                <div class="left">问题</div>
                <div class="right" v-if="isProblemOpen" @click="goProblem">更多<van-icon name="arrow" /></div>
            </div>
            <div class="list-item" v-for="item in problemList" :key="item.id" @click="goProblemInfo(item.id,item.status)">
                <div class="item-top">
                    <div class="top-left">{{item.name}} {{item.phoneNumber}} <img v-if="item.phoneNumber" class="mobile" :src="require('@/assets/img/mobile.png')" alt=""></div>
                    <div class="top-right"
                        :class="{
                            'unfinished': item.status == 0 || item.status == 10 || item.status == 20,
                            'finished': item.status == 30 || item.status == 40 || item.status == 50,
                        }"
                    >
                        {{item.statusStr}}
                    </div>
                </div>
                <div class="item-mid">
                    <van-image :src="item.url || require('@/assets/img/default.png')" class="content-img">
                        <template slot="loading">
                            <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                        </template>
                        <template slot="error">
                            <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                        </template>
                    </van-image>
                    <div class="mid-text">
                        <div class="text-top">{{item.houseFullName}}<span>（{{item.typeStr}}）</span></div>
                        <div class="text-content">{{item.content}}</div>
                    </div>
                    <van-icon size="20" name="arrow" />
                </div>
                <div class="item-down">
                    <div class="down-left">{{item.createDate}}</div>
                    <div class="down-right">处理</div>
                </div>
            </div>
            <van-image v-if="problemList.length < 1" :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
        </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import {getAppToken} from '@/utils/common'
import {getImageStream} from '@/utils'
export default {
    props: ['houseId','isRepairsOpen','repairAppInfo','isProblemOpen','problemAppInfo'],
    data() {
        return {
            repairList: [],
            problemList: [],
            repairTotalCount: '',
            problemTotalCount: '',
            // isRepairsOpen: false,
            // isProblemOpen: false,
            // repairAppInfo: {},
            // problemAppInfo: {}
        }
    },
    ...mapMutations(['setProblemData', 'setRepairData', 'setVisitId', 'setVisitData']),
    mounted() {
        if(this.isRepairsOpen) {
            this.getRepairList()
        }
        if(this.isProblemOpen) {
            this.getProblemList()
        }
    },
    methods: {
        // 报修列表
        getRepairList() {
            this.$httpApp({
                url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/list'),
                method: 'post',
                params: this.$httpApp.adornParams({
                    page: '1',
                    limit: '5',
                    houseId: this.houseId
                })
            }).then(({data}) => {
                if (data.code == 0) {
                    this.repairTotalCount = data.page.totalCount
                    data.page.list.forEach(item => {
                        if (item.fileDisplayVOs && item.fileDisplayVOs.length != 0) {
                            let file = item.fileDisplayVOs[0]
                            let url = file.relativeUrl
                            if (url) {
                                url = getImageStream(url)
                                item["url"] = url
                            }
                        }
                    })
                    this.repairList = data.page.list
                }
            }, err => {
            })
        },
        // 跳转报修详情
        goRepairInfo (id, status) {
            this.$router.push({
                path: '/appSSO',
                query: {
                    houseId: this.houseId + '',
                    appId: this.repairAppInfo.appId,
                    appCode: this.repairAppInfo.appCode,
                    appName: this.repairAppInfo.appName,
                    url: this.repairAppInfo.pageUrlH5,
                    dataId: id,
                    dataStatus: status,
                }
            })
        },
        goRepair() {
            this.$router.push({
                path: '/appSSO',
                query: {
                    houseId: this.houseId + '',
                    appId: this.repairAppInfo.appId,
                    appCode: this.repairAppInfo.appCode,
                    appName: this.repairAppInfo.appName,
                    url: this.repairAppInfo.pageUrlH5,
                }
            })
        },
        // 问题列表
        getProblemList() {
            this.$httpApp({
                url: this.$httpApp.adornUrl('/wxapp/problem/list'),
                method: 'post',
                params: this.$httpApp.adornParams({
                    page: '1',
                    limit: '4',
                    houseId: this.houseId
                })
            }).then(({data}) => {
                if (data.code == 0) {
                    this.problemTotalCount = data.list.totalCount
                    data.list.list.forEach(item => {
                        if (item.problemFiles && item.problemFiles.length != 0) {
                            let file = item.problemFiles[0]
                            let url = file.filePath
                            if (url) {
                                url = getImageStream(url)
                                item["url"] = url
                            }
                        }
                    })
                    this.problemList = data.list.list
                    // 加载状态结束
                }
                this.$toast.clear()
            }, err => {
                this.$toast.clear()
            })
        },
        // 跳转问题详情
        goProblemInfo(id, status) {
            this.$router.push({
                path: '/appSSO',
                query: {
                    houseId: this.houseId + '',
                    appId: this.problemAppInfo.appId,
                    appCode: this.problemAppInfo.appCode,
                    appName: this.problemAppInfo.appName,
                    url: this.problemAppInfo.pageUrlH5,
                    dataId: id,
                    dataStatus: status,
                }
            })
        },
        goProblem() {
            this.$router.push({
                path: '/appSSO',
                query: {
                    houseId: this.houseId + '',
                    appId: this.problemAppInfo.appId,
                    appCode: this.problemAppInfo.appCode,
                    appName: this.problemAppInfo.appName,
                    url: this.problemAppInfo.pageUrlH5,
                }
            })
        },
    }
}
</script>

<style scoped>
.van-tabs >>> .van-tab {
  font-size: 32px;
}
.van-tabs >>> .van-tab--active {
  color: #4581F8;
}
.van-tabs >>> .van-tabs__line {
  background-color: #4581F8;
}
</style>
<style lang="scss" scoped>
.page-info {
    .totalcount {
        height: 56px;
        font-size: 24px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #999999;
        line-height: 56px;
        text-align: center;
        span {
            color: #666666;
        }
    }
    .title {
        height: 96px;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        .left {
            font-size: 32px;
            font-weight: 600;
            color: #333333;
        }
        .right {
            font-size: 28px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #666666;
        }
    }
    .list-item {
        padding: 0 30px;
        background-color: #FFFFFF;
        margin-bottom: 16px;
        .item-top {
            // height: 102px;
            padding: 30px 0;
            border-bottom: 1px solid rgba(0,0,0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .top-left {
                font-size: 28px;
                font-family: 'PingFangSC-Regular';
                font-weight: 400;
                color: #666666;
                .mobile {
                    width: 40px;
                    height: 40px;
                    margin-left: 16px;
                }
            }
            .top-right {
                // width: 128px;
                // height: 52px;
                padding: 9px 20px;
                border-radius: 8px;
                font-size: 24px;
                font-weight: 600;
                // line-height: 52px;
                // text-align: center;
            }
            .unfinished {
                color: #E9564B;
                background: rgba(233,86,75,0.12);
            }
            .finished {
                background: rgba(69,129,248,0.12);
                color: #4581F8;
            }
        }
        .item-mid {
            padding: 24px 0;
            display: flex;
            align-items: center;
            .content-img {
                width: 160px;
                height: 160px;
                border-radius: 16px;
                margin-right: 16px;
            }
            .mid-text {
                flex: 1;
                height: 160px;
                display: flex;
                flex-direction: column;
                .text-top {
                    font-size: 28px;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 16px;
                    span {
                        font-weight: 400;
                        color: #666666;
                    }
                }
                .text-content {
                    font-size: 28px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
        .item-down {
            padding: 22px 0;
            border-top: 1px solid rgba(0,0,0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .down-left {
                font-size: 28px;
                font-family: 'PingFangSC-Regular';
                font-weight: 400;
                color: #666666;
            }
            .down-right {
                width: 112px;
                height: 60px;
                background: #4581F8;
                border-radius: 8px;
                font-size: 28px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 60px;
                text-align: center;
            }
        }
    }
}
</style>
